import React, { useEffect, useState } from "react";
import { Headers } from "../../../api/actions";
import { Api } from "../../../api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { signIn } from "../../../features/user/userSlice";
import { useNavigate } from "react-router";
import axiosInstance from "../../../api/axios_instance";
import swal from "sweetalert";
import { Link, useSearchParams } from "react-router-dom";

const OtpForm = () => {
  const [otp, setOtp] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const time = searchParams.get("expire");
  const url = searchParams.get("url");
  const msg = searchParams.get("msg");
  const SingInData = async (e) => {
    e.preventDefault();
    // https://www.etsapp.somee.com/api/'
    const options = {
      method: "POST",
      url: `${Api}ApplicationDbUser/ValidateOTP`,
      headers: Headers,
      data: { AuthURL: url, codeOTP: otp },
    };
    await axiosInstance(options)
      .then(function (response) {
        if (response.data.responseStatus.code !== 0) {
          swal(
            response.data.responseStatus.errors
              .map((err) => err.errorMessage)
              .join("\n"),
            {
              icon: "error",
              button: {
                text: "إغلاق",
                closeModal: true,
              },
            }
          );
          // .then(() => navigate("/signin"));
        } else {
          const data = response.data.responseObject;

          const {
            fullName: username,
            userName: militaryNumber,
            roles,
            token,
            userMenu,
          } = response.data.responseObject;
          const menu = userMenu.map((itm) => itm.menuName);
          dispatch(signIn({ username, roles, token, menu, militaryNumber }));
          navigate("/");
        }
      })
      .catch(function (error) {});
  };

  return (
    <div className="singin__content__form">
      <div className="singin__content__form__title">
        <img src="/assets/images/logo-light.jfif" alt="" />
        <h2>كود التحقيق</h2>
        <p>{msg}</p>
      </div>

      <form action="" onSubmit={SingInData}>
        <InputOtp otp={otp} setOtp={setOtp} />
        <Countdown targetDate={new Date(time)} />

        <div className="form-group-button">
          <button type="submit" className="btn">
            <span>ارسال</span>
          </button>
        </div>
      </form>
    </div>
  );
};
export default OtpForm;
export const InputOtp = (props) => {
  const { otp, setOtp } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setOtp(e.target.value);
  };
  return (
    <div className="input-group-password">
      <img src="/assets/icons/otp-icon.svg" alt="" />
      <input
        type={"text"}
        placeholder="كود التحقيق"
        value={otp}
        onChange={(e) => {
          OnChinge(e);
        }}
      />
    </div>
  );
};

function Countdown({ targetDate }) {
  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(targetDate)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const time = calculateRemainingTime(targetDate);
      setRemainingTime(time);

      if (time.total <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  function calculateRemainingTime(target) {
    const now = new Date();
    const difference = target.getTime() - now.getTime();

    if (difference <= 0) {
      return {
        total: 0,
        minutes: 0,
        seconds: 0,
        message: "انتهت صلاحية الكود!",
      };
    }

    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      total: difference,
      minutes,
      seconds,
      message: "",
    };
  }

  const formattedTime = `${remainingTime.minutes
    .toString()
    .padStart(2, "0")}:${remainingTime.seconds.toString().padStart(2, "0")}`;

  return (
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      {remainingTime.message ? (
        <>
          <p>{remainingTime.message}</p>
          <Link
            to={"/signin"}
            style={{ color: "#5570f1", textDecoration: "underline" }}
          >
            حاول مجددا!
          </Link>
        </>
      ) : (
        <p>
          ستنتهي صلاحية الكود خلال:{" "}
          <span style={{ color: "#5570f1" }}>{formattedTime}</span>
        </p>
      )}
    </div>
  );
}
