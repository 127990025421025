//export const Api = "https://etsapp-001-site1.ctempurl.com/api/";
// export const Api = "https://ahmedgalal75.bsite.net/api/";

// export const Api = "https://rydhqexamapp01/api/";
// export const Notify = "https://rydhqexamapp01/notify";

export const Api = "https://www.ets005.somee.com/api/";
export const Notify = "https://www.ets005.somee.com/notify";

/*
export const TokenLate = JSON.parse(localStorage.getItem("tokenlate"));
export const languagelate = JSON.parse(localStorage.getItem("languagelate"));
export const UserTypelate = JSON.parse(localStorage.getItem("usertypelate"));
export const UserIdlate = JSON.parse(localStorage.getItem("useridlate"));
*/
//localStorage.setItem("tokenclicklized", JSON.stringify(response.data.data.token));
// window.location.pathname = "/";
// Authorization: `Bearer ${JSON.parse(localStorage.getItem("tokenclicklized"))}`,

/*
//export const Api = "https://admin.late.hwzn.sa/api/";
//export const Api = "https://admin.lawaty.com.sa/api/";
*/
// https://rydhqexamapp01/
