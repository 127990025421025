/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Api } from "../../../../api";
import { Headers } from "../../../../api/actions";
import SurveyViewTopDropdownClassRoomName, {
  CourseNumber,
} from "./survey-view-top-dropdown-classroom";
//import SurveyViewTopDropdownSubject from "./survey-view-top-dropdown-subject";
import SurveyViewTopDatePicker from "./survey-view-top-dateRange";
import IconSearch from "../../../../components/shared/icon-search";
import axiosInstance from "../../../../api/axios_instance";
import { useSearchParams } from "react-router-dom";
import { FilterReset } from "../../../../components/shared/reset-filter";
import { useArrayQueryParam } from "./array-search-param-hook";

const SurveyViewTop = (props) => {
  const { setStartDay, setEndDay, setIsLoading, classRoomID } = props;
  const { id, search: searchKey } = useParams();
  const searchKeyword = searchKey.slice(
    searchKey.indexOf(":") + 1,
    searchKey.length
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const fromDate = searchParams.get("fromDate") || "";
  const toDate = searchParams.get("toDate") || "";
  const [subjects, setSubjects] = useArrayQueryParam("subjects");
  const [classRooms, setClassRooms] = useArrayQueryParam("classRooms");
  const [reset, setReset] = useState("1");

  const [classRoomNumbers, setClassRoomNumbers] =
    useArrayQueryParam("classRoomNumbers");
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    GetData(id, setName, setLoading);
  }, [id]);
  const [search, setSearch] = useState("");
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(
      `/survey/survey-view/${id}/page:1/search:${search}/sortclassroom:`
    );
  };

  const updateClassRoomNumber = (classRoomNumber) => {
    setClassRoomNumbers(classRoomNumber);
  };

  if (!loading) {
    return (
      <div className="survey-view__top">
        <div className="survey-view__top__title">
          <h5>استبيان</h5>
        </div>

        <div className="survey-view__top__filter">
          <div className="survey-view__top__search">
            <IconSearch SearchClick={SearchClick} />
            <input
              type="text"
              placeholder="بحث"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  navigate(
                    `/survey/survey-view/${id}/page:1/search:${e.target.value}/sortclassroom:`
                  );
                  // setIsLoading(false);
                }
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-view__top">
      <div className="survey-view__top__title">
        <h5>{name}</h5>
      </div>

      <div className="survey-view__top__filter">
        <div className="survey-view__top__search">
          <IconSearch SearchClick={SearchClick} />
          <input
            defaultValue={searchKeyword}
            type="text"
            placeholder="بحث"
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                navigate(
                  `/survey/survey-view/${id}/page:1/search:${e.target.value}/sortclassroom:`
                );
              }
              setSearch(e.target.value);
            }}
          />
        </div>
        <SurveyViewTopDatePicker
          setStartDay={setStartDay}
          setEndDay={setEndDay}
          setIsLoading={setIsLoading}
        />
        {id != "4" && (
          <QuestionSubjects
            key={reset}
            subjectIds={subjects}
            setSubjectIds={setSubjects}
            classRooms={classRooms}
          />
        )}{" "}
        {id != "4" && (
          <SurveyViewTopDropdownClassRoomName
            key={reset + "2"}
            subjectIds={subjects}
            setSubjectIds={setSubjects}
          />
        )}
        {id != "4" && !!classRoomID?.length && (
          <CourseNumber
            classRoomNumber={classRoomNumbers}
            setClassRoomNumber={updateClassRoomNumber}
            classRoomId={classRoomID}
          />
        )}
        {(searchKeyword ||
          subjects?.length ||
          classRoomID?.length ||
          classRoomNumbers?.length ||
          fromDate ||
          toDate) && (
          <FilterReset
            resetFilters={() => {
              setReset(Math.random() + "");
              navigate(
                `/survey/survey-view/${id}/page:1/search:/sortclassroom:`
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SurveyViewTop;

const QuestionSubjects = (props) => {
  const { subjectIds, setSubjectIds, classRooms } = props;

  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSubjectClassRooms(setData, setLoading, classRooms || []);
  }, [JSON.stringify(classRooms)]);

  useEffect(() => {
    setSubjectIds(
      subjectIds.filter((id) => data.some((itm) => itm.value === id))
    );
  }, [JSON.stringify(data)]);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">المواد</span>
        </button>
      </div>
    );
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">المواد</span>
      </button>
      <ul className="dropdown-menu">
        <input
          style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
          type="text"
          placeholder="بحث"
          onChange={(e) => {
            if (e.target.value)
              setSearchedData(
                data.filter((item) => item.label.includes(e.target.value))
              );
            else setSearchedData(undefined);
          }}
        />
        {(searchedData || data).map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.code}
                checked={subjectIds?.includes(item.value)}
                id="flexCheckDefault"
                onChange={(e) => {
                  e.preventDefault();
                  setSubjectIds(
                    subjectIds.includes(item.value)
                      ? subjectIds.filter((value) => value != item.value)
                      : [...subjectIds, item.value]
                  );
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.label}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const GetData = async (Id, setName, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}Survey/GetSurveyTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setLoading(true);
      const Array = response.data.responseObject;
      for (let i = 0; i < Array.length; i++) {
        if (Array[i].id == Id) {
          setName(Array[i].name);
        }
      }
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataSubjectClassRooms = async (setData, setLoading, classRooms) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetSubjectsSelectListByClassroomIds`,
    headers: Headers,
    data: classRooms,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(
        response.data.responseObject.map((itm) => ({
          value: itm.id,
          label: itm.name + " - " + itm.code,
          code: itm.code,
        }))
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
