import React from "react";
import SinginForm from "./singin-form";
import OtpForm from "./otp-form";
import { useSearchParams } from "react-router-dom";

const SinginContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="singin__content">
      <div className="ovload-img">
        {searchParams.get("otp") === "1" ? <OtpForm /> : <SinginForm />}
        <div className="singin-logo">
          <img src="/assets/images/logo-dark.png" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default SinginContent;
