/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Api } from "../../../../api";
import { Headers } from "../../../../api/actions";
import axios from "axios";
import axiosInstance from "../../../../api/axios_instance";
import { useSearchParams } from "react-router-dom";
import { useArrayQueryParam } from "./array-search-param-hook";

const SurveyViewTopDropdownClassRoomName = (props) => {
  const { id } = useParams();
  const [classRooms, setClassRooms] = useArrayQueryParam("classRooms");
  const [subjects] = useArrayQueryParam("subjects");
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState(undefined);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataClassRoomsNumbersBySubjectIds(subjects, setData, setLoading);
  }, [JSON.stringify(subjects)]);

  useEffect(() => {
    setClassRooms(classRooms.filter((id) => data.some((itm) => itm.id === id)));
  }, [JSON.stringify(data)]);

  if (!loading) {
    return (
      <button className="btn btn-show-dropdown" type="button">
        {/* <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span> */}
        <span className="text">اسم ورمز الدورة </span>
      </button>
    );
  }
  const handleChange = (event) => {
    const { value } = event.currentTarget;
    setClassRooms(
      classRooms.includes(value)
        ? classRooms.filter((val) => value !== val)
        : [...classRooms, value]
    );
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {/* <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span> */}
        <span className="text">اسم ورمز الدورة </span>
      </button>
      <ul
        className="dropdown-menu"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <input
          style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
          type="text"
          placeholder="بحث"
          onChange={(e) => {
            if (e.target.value)
              setSearchedData(
                data.filter(
                  (item) =>
                    item.name.includes(e.target.value) ||
                    item.code.includes(e.target.value)
                )
              );
            else setSearchedData(undefined);
          }}
        />
        {(searchedData || data).map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                name="classRoomIDCheckDefault"
                onChange={handleChange}
                checked={classRooms.includes(item.id) ? true : null}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>
                  {item.name} - {item.code}
                </span>
              </label>
            </li>
          );
        })}
        {/* <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/survey/survey-view/${id}/page:1/search:/sortclassroom:${value}`
            );
            setIsLoading(false);
          }}
        >
          <span>تصفية</span>
        </button> */}
      </ul>
    </div>
  );
};
export default SurveyViewTopDropdownClassRoomName;

const GetData = async (setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("pageSize", 100000);
  const options = {
    method: "post",
    url: `${Api}ClassRoom/SearchForClassRooms`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetDataClassRoomsNumbersBySubjectIds = async (
  subjectIds,
  setData,
  setLoading
) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersSelectListBySubjectIds`,
    headers: Headers,
    data: subjectIds,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

export const CourseNumber = (props) => {
  const { classRoomNumber, setClassRoomNumber, classRoomId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleData = (data) => {
    if (data) setData(data);
  };
  useEffect(() => {
    if (classRoomId)
      GetDataNumberClassRooms(classRoomId, null, handleData, setLoading);
    else setData([]);
    setClassRoomNumber([]);
  }, [JSON.stringify(classRoomId)]);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">رقم الدورة</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text"> رقم الدورة </span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={classRoomNumber.includes(item.id)}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomNumber(
                    classRoomNumber.includes(item.id)
                      ? classRoomNumber.filter((val) => item.id !== val)
                      : [...classRoomNumber, item.id]
                  );
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.name}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const GetDataNumberClassRooms = async (
  classRoomIds,
  subjectId,
  setData,
  setLoading
) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersSelectListByCourseIds`,
    headers: Headers,
    data: classRoomIds,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
