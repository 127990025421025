import { useSearchParams } from "react-router-dom";

export const useArrayQueryParam = (key) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setArray = (array) => {
    // Create a new URLSearchParams object from the existing search parameters
    const params = new URLSearchParams(searchParams);

    // Remove all existing values for the specified key
    params.delete(key);

    // Append the new values for the specified key
    array.forEach((item) => {
      params.append(key, item);
    });

    // Update the URL with the modified search parameters
    setSearchParams(params);
  };

  const getArray = () => {
    return searchParams.getAll(key);
  };

  return [getArray(), setArray];
};
