import React from "react";
import SurveyViewTop from "./survey-view-top";
import SurveyViewTable from "./survey-view-table";
import Loading from "../../../../components/loading/loading";

const SurveyViewContent = (props) => {
  const {
    ArrayData,
    setStartDay,
    setEndDay,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
    classRoomID,
    downloadFile,
    loading,
  } = props;
  return (
    <div className="survey-view">
      <SurveyViewTop
        setStartDay={setStartDay}
        setEndDay={setEndDay}
        setIsLoading={setLoading}
        classRoomID={classRoomID}
      />
      {!!ArrayData?.length && (
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <button
            onClick={() => {
              downloadFile(2);
            }}
            className="btn"
            style={{ backgroundColor: "#f20f00", color: "white" }}
          >
            <img
              className="mx-2"
              height={30}
              src="/assets/icons/pdf.svg"
              alt="pdf"
            />
            تحميل كــــ pdf
          </button>
          <button
            onClick={() => {
              downloadFile(1);
            }}
            className="btn"
            style={{ backgroundColor: "#1c6c3f", color: "white" }}
          >
            <img
              className="mx-2"
              height={30}
              src="/assets/icons/xlsx.svg"
              alt="pdf"
            />
            تحميل كــــ xlsx
          </button>
        </div>
      )}
      {loading ? (
        <SurveyViewTable
          ArrayData={ArrayData}
          perpage={perpage}
          pagenumber={pagenumber}
          setLoading={setLoading}
          PageSize={PageSize}
          setPageSize={setPageSize}
        />
      ) : (
        <div className="main-body__content">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default SurveyViewContent;
