import React, { useEffect, useState } from "react";
import { Api } from "../../../api/index";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";
import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import { useParams } from "react-router";
import axiosInstance from "../../../api/axios_instance";
import { useSearchParams } from "react-router-dom";
import ShowSurveyTable, {
  Paginate,
} from "../component/show-survey/show-survey-table";
import { downloadBase64FileWithoutAtob } from "../../../components/shared/utils";

const SurveyQuestions = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const page_size = searchParams.get("page_size") || "10";
  const [pageCount, setPageCount] = useState(0);
  const handlePageClick = (data) => {
    setSearchParams({ page: data.selected + 1, page_size: page_size });
  };
  const setPageSize = (page_size) => {
    setSearchParams({ page: 1, page_size: page_size });
  };

  useEffect(() => {
    GetData(id, page, page_size, setData, setLoading);
  }, [id, page, page_size]);

  const ObJectArrayData = [
    {
      id: 2,
      href: "/survey",
      title: "الاستبيانات",
    },
  ];
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <div className="survey-view">
            <div className="survey-view__top">
              <div className="survey-view__top__title">
                <h5>
                  رقم الموظف : <span>{id}</span>
                </h5>
              </div>
            </div>
            {/* {!!data?.items?.length && (
              <div style={{ display: "flex", gap: "10px", width: "100%" }}>
                <button
                  onClick={() => {
                    downloadFile(id, 2);
                  }}
                  className="btn"
                  style={{ backgroundColor: "#f20f00", color: "white" }}
                >
                  <img
                    className="mx-2"
                    height={30}
                    src="/assets/icons/pdf.svg"
                    alt="pdf"
                  />
                  تحميل كــــ pdf
                </button>
                <button
                  onClick={() => {
                    downloadFile(id, 1);
                  }}
                  className="btn"
                  style={{ backgroundColor: "#1c6c3f", color: "white" }}
                >
                  <img
                    className="mx-2"
                    height={30}
                    src="/assets/icons/xlsx.svg"
                    alt="pdf"
                  />
                  تحميل كــــ xlsx
                </button>
              </div>
            )} */}
            <ListSurveyStudentsHead />
            <div className="table-container">
              <ul className="table-container__body">
                {data?.items?.map &&
                  data?.items?.map((item, index) => {
                    return (
                      <li key={index}>
                        <ListSurveyStudentsBody
                          Qutchen={item.surveyQuestion}
                          Answer={item.answer}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <Paginate
            handlePageClick={handlePageClick}
            perpage={Math.ceil(data?.totalItemsCount / page_size)}
            pagenumber={page}
            PageSize={page_size}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </section>
  );
};

export default SurveyQuestions;

const GetData = async (id, page, pageSize, setData, setLoading) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Survey/SearchForStaffSurvey`,
    headers: Headers,
    data: {
      pageNumber: page,
      pageSize: pageSize,
      staffId: id,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      console.log(response.data.responseObject);

      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const downloadFile = async (id, exportTypeId) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Survey/SearchForStaffSurvey`,
    headers: Headers,
    data: {
      isExport: true,
      exportTypeId: exportTypeId,
      staffId: id,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      const file = response.data;
      downloadBase64FileWithoutAtob(
        file.fileContents,
        file.fileDownloadName,
        file.contentType
      );
    })
    .catch(function (error) {});
};
const ListSurveyStudentsHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span>السؤال</span>
        <span>الأجابه</span>
      </li>
    </ul>
  );
};

const ListSurveyStudentsBody = (props) => {
  const { Qutchen, Answer } = props;
  return (
    <>
      <span>{Qutchen}</span>
      <span>{Answer}</span>
    </>
  );
};
