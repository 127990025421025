import React, { useEffect, useState } from "react";
import ChartTop from "./charts-top";
import {
  ChartSurveyQuestions,
  ChartQuestionnaireStatistics,
} from "./chart-doughnut";
import { downloadBase64FileWithoutAtob } from "../../shared/utils";
import axiosInstance from "../../../api/axios_instance";
import { Headers } from "../../../api/actions";
import { Api } from "../../../api";
const ChartsContent = (props) => {
  const {
    Arraydata,
    surveyTypeIds,
    setSurveyTypeIds,
    classRoomCodes,
    setClassRoomCodes,
    classRoomNumbers,
    setClassRoomNumbers,
    classRoomNames,
    setClassRoomNames,
    subjectCodes,
    setSubjectCodes,
    surveyTypeName,
    setSurveyTypeName,
  } = props;
  const [subjectIds, setSubjectIds] = useState([]);
  const [classRoomIds, setClassRoomIds] = useState([]);

  const isNoData =
    (!Arraydata.choice?.data?.length ||
      Arraydata.choice?.data?.every((itm) => itm === "0")) &&
    (!Arraydata.trueFalse?.data?.length ||
      Arraydata.trueFalse?.data?.every((itm) => itm === "0"));

  // useEffect(() => {
  //   setClassRoomCodes([]);
  //   setClassRoomIds([]);
  //   setSubjectIds([]);
  //   setSubjectCodes([]);
  // }, [surveyTypeIds]);

  useEffect(() => {
    setClassRoomNumbers([]);
  }, [classRoomIds]);
  return (
    <>
      <ChartTop
        subjectIds={subjectIds}
        setSubjectIds={setSubjectIds}
        surveyTypeIds={surveyTypeIds}
        setSurveyTypeIds={setSurveyTypeIds}
        classRoomCodes={classRoomCodes}
        setClassRoomCodes={setClassRoomCodes}
        classRoomNumbers={classRoomNumbers}
        setClassRoomNumbers={setClassRoomNumbers}
        classRoomNames={classRoomNames}
        setClassRoomNames={setClassRoomNames}
        subjectCodes={subjectCodes}
        setSubjectCodes={setSubjectCodes}
        setClassRoomIds={setClassRoomIds}
        classRoomIds={classRoomIds}
        surveyTypeName={surveyTypeName}
        setSurveyTypeName={setSurveyTypeName}
      />
      {!isNoData && !!surveyTypeIds?.length && (
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <button
            onClick={() => {
              GetDataFile(
                surveyTypeIds[0],
                classRoomIds,
                classRoomNumbers,
                subjectIds,
                2
              );
            }}
            className="btn"
            style={{ backgroundColor: "#f20f00", color: "white" }}
          >
            <img
              className="mx-2"
              height={30}
              src="/assets/icons/pdf.svg"
              alt="pdf"
            />
            تحميل كــــ pdf
          </button>
          <button
            onClick={() => {
              GetDataFile(
                surveyTypeIds[0],
                classRoomIds,
                classRoomNumbers,
                subjectIds,
                1
              );
            }}
            className="btn"
            style={{ backgroundColor: "#1c6c3f", color: "white" }}
          >
            <img
              className="mx-2"
              height={30}
              src="/assets/icons/xlsx.svg"
              alt="pdf"
            />
            تحميل كــــ xlsx
          </button>
        </div>
      )}

      <div className="home-content__charts-row">
        <ChartSurveyQuestions
          surveyTypeName={surveyTypeName}
          setSurveyTypeName={setSurveyTypeName}
          Arraydata={Arraydata.choice}
        />
        <ChartQuestionnaireStatistics Arraydata={Arraydata.trueFalse} />
      </div>
    </>
  );
};

export default ChartsContent;

/*

    <div className='home-content__charts-row'>
      <ChartCourses />
      <ChartTheExams />
      <ChartTheStudents />
    </div>

    <div className='home-content__charts-row'>
      <ChartQuestionnaires />
      <ChartSystemUsers />
    </div>

*/
const GetDataFile = async (
  id,
  classRooms,
  classRoomNumbers,
  subjects,
  fileType // 1 excel - 2 pdf
) => {
  const options = {
    method: "post",
    url:
      id == "4"
        ? `${Api}Survey/SearchForStaffSurvey`
        : `${Api}Survey/SearchForSurvey`,
    headers: Headers,
    data: {
      // pageNumber: 0,
      // pageSize: 0,
      isExport: true,
      exportTypeId: fileType, //1:Excel,2:PDF
      surveyTypeId: id,
      classRoomNumbers: classRoomNumbers,
      classRooms: classRooms,
      subjects: id !== "3" ? [] : subjects || [],
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      const file = response.data;
      downloadBase64FileWithoutAtob(
        file.fileContents,
        file.fileDownloadName,
        file.contentType
      );
    })
    .catch(function (error) {});
};
