import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { ObjectData } from "../../navbar/navbar_left";
import { signOut } from "../../../features/user/userSlice";

function AuthGuard({ requiredRoles, isRequireAll = false, children }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const roles = user.roles;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user.token && location.pathname !== "/signin") navigate("/signin");
    else if (location.pathname === "/signin" && user.token) navigate("/");
  }, [user, children, navigate, location.pathname]);

  useEffect(() => {
    if (!requiredRoles?.length) return;
    if (
      isRequireAll
        ? !roles?.every((role) => requiredRoles.includes(role))
        : !roles?.some((role) => requiredRoles.includes(role))
    ) {
      const allowedTarget = ObjectData.find(
        (itm) => !itm.check || roles?.includes(itm.check)
      );
      if (allowedTarget) navigate(allowedTarget.href, { replace: true });
      else {
        dispatch(signOut());
        navigate("/signin");
      }
    }
  }, [dispatch, isRequireAll, navigate, requiredRoles, roles]);

  return (
    <>
      {((user.token && location.pathname !== "/signin") ||
        (!user.token && location.pathname === "/signin")) &&
        children}
    </>
  );
}

export default AuthGuard;
