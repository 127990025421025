import React, { useState } from "react";
import DatePicker from "react-datepicker";

const SurveyViewTopDatePicker = (props) => {
  const { setStartDay, setEndDay, setIsLoading } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const calRef = React.useRef();

  const OnChinge = (update) => {
    setDateRange(update);
    setStartDay(update[0]);
    setEndDay(update[1]);
    if (update[0] !== null && update[1] !== null) {
      setIsLoading(true);
    }
  };

  return (
    <DatePicker
      ref={calRef}
      selectsRange={true}
      showMonthDropdown
      showYearDropdown
      startDate={startDate}
      endDate={endDate}
      shouldCloseOnSelect={false}
      onChange={OnChinge}
      isClearable={true}
      customInput={<CustomInput />}
    >
      <div className="react-datepicker-buttons">
        <button
          className="btn"
          onClick={() => {
            calRef.current.setOpen(false);
          }}
        >
          {dateRange[0] && dateRange[1] ? "تصفية" : "غلق"}
        </button>
      </div>
    </DatePicker>
  );
};
export default SurveyViewTopDatePicker;
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    readOnly
    style={{ cursor: "pointer", width: "100%" }}
    placeholder="بحث حسب تاريخ الاختبار "
  />
));
