import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { downloadBase64FileWithoutAtob } from "../../../components/shared/utils.js";
import axiosInstance from "../../../api/axios_instance.jsx";
import ComponentGuard from "../../../components/shared/guards/component.guard.jsx";

const ExamsTable = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;
  const { search } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  let navigate = useNavigate();
  const [selectedTest, setSelectedTest] = useState(null);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(`/exams/page:${number}/search:${searchKeyword}`);
    setLoading(false);
  };
  return (
    <div className="table-container">
      <table style={{ width: "100%", overflowX: "auto" }}>
        <ExamsTableHead />
        <tbody className="table-container__body">
          {ArrayData.map((item) => {
            return (
              <ExamsTableBody
                Item={item}
                setSelectedTest={setSelectedTest}
                key={item.id}
              />
            );
          })}
        </tbody>
      </table>
      <>
        <ModalExtendTime Id={selectedTest} />
        <Paginate
          handlePageClick={handlePageClick}
          perpage={perpage}
          pagenumber={pagenumber}
          setLoading={setLoading}
          PageSize={PageSize}
          setPageSize={setPageSize}
        />
      </>
    </div>
  );
};

export default ExamsTable;

const ExamsTableHead = () => {
  return (
    <tr className="table-container__head" style={{ textAlign: "center" }}>
      <th className="justify-content-start">رمز الدورة</th>
      <th>اسم الدورة</th>
      <th>رقم الدورة</th>
      <th>رمز المادة</th>
      <th>اسم المادة</th>
      <th>تاريخ الإنشاء</th>
      <th>تاريخ الاختبار</th>
      <th className="justify-content-end">الإجراءات</th>
    </tr>
  );
};

const ExamsTableBody = (props) => {
  const { Item, setSelectedTest } = props;

  return (
    <tr style={{ textAlign: "center" }}>
      <td className="justify-content-start">{Item.classRoomCode}</td>
      <td>{Item.classRoomName} </td>
      <td>{Item.classRoomNumber} </td>
      <td>{Item.subjectCode}</td>
      <td>{Item.subjectName}</td>
      <td>{Item.creationDate}</td>
      <td>{Item.testDate}</td>
      <td style={{ display: "flex" }} className="justify-content-center">
        <div className="dropdown">
          <button
            className="btn btn-show-dropdown"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: "none" }}
          >
            <span className="text">
              <img
                style={{ height: "30px" }}
                src="/assets/icons/options.svg"
                alt="edit"
              />
            </span>
          </button>
          <div className="dropdown-menu">
            <ul style={{ display: "flex", justifyItems: "center" }}>
              <ComponentGuard requiredRoles={["ViewTestStudents"]}>
                <li>
                  <NavLink
                    to={`/materials/material-subject/page:1/search:/subjectId:${Item.subjectId}/classroomId:${Item.classRoomId}/classroomNumber:${Item.classRoomNumber}`}
                    className="btn btn-edit"
                    title="الطلاب الممتحنين"
                  >
                    <img
                      style={{ width: "20px" }}
                      src="/assets/icons/users.svg"
                      alt="edit"
                    />
                  </NavLink>
                </li>
              </ComponentGuard>

              <ComponentGuard requiredRoles={["EditTest"]}>
                <li>
                  <NavLink
                    to={`/exams/edit/${Item.id}/subjectid:${Item.subjectId}/classroomid:${Item.classRoomId}`}
                    className="btn btn-edit"
                    title="تعديل"
                  >
                    <img
                      style={{ width: "20px" }}
                      src="/assets/icons/icon-edit.svg"
                      alt="edit"
                    />
                  </NavLink>
                </li>
              </ComponentGuard>

              <ComponentGuard requiredRoles={["ViewAllTestStudents"]}>
                <li>
                  <NavLink
                    to={`/exams/list-exams/${Item.id}/subjectid:${Item.subjectId}/classroomid:${Item.classRoomId}`}
                    className="btn btn-show"
                    title="تفاصيل"
                  >
                    <img
                      style={{ width: "20px" }}
                      src="/assets/icons/eye-show.svg"
                      alt="details"
                    />
                  </NavLink>
                </li>
              </ComponentGuard>

              <ComponentGuard requiredRoles={["DeleteTest"]}>
                <li>
                  <button
                    type="button"
                    className="btn btn-remove"
                    data-bs-toggle="modal"
                    data-bs-target={`#ModalDeleteExams-${Item.id}`}
                    title="حذف"
                  >
                    <img
                      style={{ width: "20px" }}
                      src="/assets/icons/icon-delete.svg"
                      alt="remove"
                    />
                  </button>
                </li>
              </ComponentGuard>
              <ComponentGuard requiredRoles={["AddTimeToTest"]}>
                <li>
                  <button
                    type="button"
                    className="btn btn-show"
                    data-bs-toggle="modal"
                    data-bs-target={`#ExtendTime`}
                    onClick={() => setSelectedTest(Item.id)}
                    title="اضافة وقت"
                  >
                    <img
                      style={{ width: "20px" }}
                      src="/assets/icons/time.svg"
                      alt="extend time"
                    />
                  </button>
                </li>
              </ComponentGuard>
              <ComponentGuard requiredRoles={["TestPDF"]}>
                <li>
                  <button
                    className="btn btn-show"
                    title="تحميل pdf"
                    onClick={() => {
                      DownloadExams(Item.id);
                    }}
                  >
                    <img
                      src="/assets/icons/pdf.png"
                      alt="edit"
                      style={{
                        width: "20px",
                        filter: "none",
                      }}
                    />
                  </button>
                </li>
              </ComponentGuard>
            </ul>
          </div>
        </div>
      </td>
      <ModalDeleteExams Id={Item.id} />
    </tr>
  );
};

function Paginate(props) {
  const {
    handlePageClick,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
  } = props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ];

  return (
    <div className="paginate_control">
      <div className="paginate_control__select">
        <label htmlFor="">
          <span>عدد الصفوف</span>
        </label>
        <select
          className="form-select"
          value={PageSize}
          onChange={(e) => {
            setPageSize(parseInt(e.target.value));
            setLoading(false);
          }}
        >
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}

const DeletQuestion = async (id) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Test/DeleteTest`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
        return;
      } else {
        swal("تم الحذف بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};

const ExtendTime = async (id, time) => {
  var data = new FormData();
  data.append("testId", id);
  data.append("minutes", time);

  const options = {
    method: "post",
    url: `${Api}Test/ExtendTestTime`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
        return;
      } else {
        swal("تم مد وقت الامتحان بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};

const ModalDeleteExams = (props) => {
  const { Id } = props;
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`ModalDeleteExams-${Id}`}
      tabIndex="-1"
      aria-labelledby="ModalDeleteExamsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <h6>هل تريد حذف الاختبار ؟</h6>
          </div>

          <div className="form-group-button">
            <button
              type="button"
              className="btn btn-line-susees"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={(e) => {
                e.preventDefault();
                DeletQuestion(Id);
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const ModalExtendTime = (props) => {
  const { Id } = props;
  const [time, setTime] = useState("0");
  const [err, setErr] = useState("");
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`ExtendTime`}
      tabIndex="-1"
      aria-labelledby="ModalExtendTime"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <div className="exams-content-add__form__card__inputs__rows__item">
              <label>المدة المضافة للاختبار</label>
              <div className="input-test-duration">
                <input
                  style={{ border: "none" }}
                  type="number"
                  placeholder="مدة الاختبار"
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                    if (
                      isNaN(+e.target.value) ||
                      +e.target.value <= 0 ||
                      +e.target.value !== Math.floor(+e.target.value)
                    )
                      setErr("يجب ان يكون الوقت المضاف عدد صحيح موجب");
                    else setErr("");
                  }}
                />
                <span className="text" style={{ margin: "0 20px" }}>
                  دقيقة
                </span>
              </div>
              {err && (
                <>
                  <br />
                  <span className="error-text">{err}</span>
                  <br />
                </>
              )}
            </div>
          </div>

          <div className="form-group-button">
            <button
              type="button"
              className="btn btn-line-susees"
              data-bs-dismiss="modal"
              onClick={() => {
                setTime("0");
                setErr("");
              }}
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={(e) => {
                e.preventDefault();
                if (err) return;
                if (isNaN(+time) || +time <= 0 || +time !== Math.floor(+time)) {
                  setErr("يجب ان يكون الوقت المضاف عدد صحيح موجب");
                  return;
                }
                ExtendTime(Id, time);
              }}
            >
              اضافة وقت
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DownloadExams = (id) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Test/ExportTest`,
    headers: Headers,
    data,
  };
  axiosInstance(options)
    .then(function (response) {
      const { fileContent, fileName } = response.data;
      downloadBase64FileWithoutAtob(fileContent, fileName, "application/pdf");
    })
    .catch(function (error) {});
};
