export function downloadBase64File(base64Data, fileName, contentType) {
  const binaryData = atob(base64Data);
  const binaryArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    binaryArray[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([binaryArray], { type: contentType });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);

  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

export async function downloadBase64FileWithoutAtob(
  base64Data,
  fileName,
  contentType
) {
  // 1) Build a data URL from the Base64
  const dataUrl = `data:${contentType};base64,${base64Data}`;

  // 2) Fetch that data URL and convert to a Blob
  const response = await fetch(dataUrl);
  const blob = await response.blob();

  // 3) Create a link for the Blob and trigger a download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // 4) (Optional) Revoke the object URL to free memory
  URL.revokeObjectURL(link.href);
}
