import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/helpers/index.jsx";
import Home from "./pages/home/index.jsx";
import ProfilePersonly from "./pages/profile-personly/index.jsx";
import Courses from "./pages/courses/index.jsx";
import Subjects from "./pages/courses/subjects/index.jsx";
import Students from "./pages/students/index.jsx";
import Materials from "./pages/materials/index.jsx";
import MaterialSubject from "./pages/materials/material-subject/index.jsx";
import Survey from "./pages/survey/index.jsx";
import SurveyView from "./pages/survey/survey-view/index.jsx";
import Notifications from "./pages/notifications/index.jsx";
import Moderators from "./pages/moderators/index.jsx";
import Powers from "./pages/powers/index.jsx";
import AddPowers from "./pages/powers/add_powers/index.jsx";
import Exams from "./pages/exams/index.jsx";
import ListStudents from "./pages/exams/list-students/index.jsx";
import Question from "./pages/questionbanck/index.jsx";
import AddAllQuestion from "./pages/questionbanck/add-all/index.jsx";
import EditeQuestion from "./pages/questionbanck/edite-question/index.jsx";
import ExamsAdd from "./pages/exams/exams_add/index.jsx";
import MaterialExam from "./pages/materials/material-exam/index.jsx";
import EditeQuestionMatching from "./pages/questionbanck/edite-question-matching/index.jsx";
import SurveyQuestions from "./pages/survey/survey-questions/index.jsx";
import ListSurveyStudents from "./pages/survey/list-survey-students/index.jsx";
import ShowSurvey from "./pages/survey/show-survey/index.jsx";
import ExamsRepetiton from "./pages/exams/exam-repetiton/index.jsx";
import ExamsEdit from "./pages/exams/exam-edit/index.jsx";
import EditPowers from "./pages/powers/edit_powers/index.jsx";
import AddQuestion from "./pages/questionbanck/add-question/index.jsx";
import { store } from "./store/store.js";
import { Provider } from "react-redux";
import SingIn from "./pages/singin/singin.jsx";
import AuthGuard from "./components/shared/guards/auth.guard.jsx";
import EditQuestion from "./pages/questionbanck/edit-question/index.jsx";
import Rounds from "./pages/courses/rounds/index.jsx";
import Agreement from "./pages/agreement/index.jsx";
import ShowStaffSurvey from "./pages/survey/survey-questions/staff-survey.jsx";

function App() {
  return (
    <main>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthGuard requiredRoles={["Home"]}>
                    <Home />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/profile-personly"
                element={
                  <AuthGuard requiredRoles={["Profile"]}>
                    <ProfilePersonly />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/courses/:page/:search/:active"
                element={
                  <AuthGuard requiredRoles={["Classrooms"]}>
                    <Courses />
                  </AuthGuard>
                }
                exact
              />

              <Route
                path="courses/:classRoomId/rounds/:roundId/subjects/:page/:search"
                element={
                  <AuthGuard requiredRoles={["ViewClassroom"]}>
                    <Subjects />
                  </AuthGuard>
                }
                exact
              />

              <Route
                path="/courses/rounds/:id/:page/:search"
                element={
                  <AuthGuard requiredRoles={["ViewClassroom"]}>
                    <Rounds />
                  </AuthGuard>
                }
                exact
              />

              <Route
                path="/materials/:page/:search/:sortclassroom"
                element={
                  <AuthGuard requiredRoles={["Subjects"]}>
                    <Materials />
                  </AuthGuard>
                }
                exact
              />

              <Route
                path="/materials/material-subject/:page/:search/:subjectId/:classroomId/:classroomNumber"
                element={
                  <AuthGuard requiredRoles={["ViewTestStudents"]}>
                    <MaterialSubject />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/materials/material-exam/:testid/:studentid"
                element={
                  <AuthGuard requiredRoles={["ViewTest"]}>
                    <MaterialExam />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/questionbanck/:page/:search/:sortclassroom"
                element={
                  <AuthGuard requiredRoles={["QuestionsBank"]}>
                    <Question />
                  </AuthGuard>
                }
              />
              <Route
                path="/questionbanck/add-all/:page/:id"
                element={
                  <AuthGuard requiredRoles={["QuestionsBank"]}>
                    <AddAllQuestion />
                  </AuthGuard>
                }
              />
              <Route
                path="/questionbanck/edite-question/:id/:subjectid"
                element={
                  <AuthGuard requiredRoles={["QuestionsBank"]}>
                    <EditeQuestion />
                  </AuthGuard>
                }
              />
              <Route
                path="/questionbanck/edit-question/:id"
                element={
                  <AuthGuard requiredRoles={["QuestionsBank"]}>
                    <EditQuestion />
                  </AuthGuard>
                }
              />
              <Route
                path="/questionbanck/add-question/:subjectid"
                element={
                  <AuthGuard requiredRoles={["AddQuestion"]}>
                    <AddQuestion />
                  </AuthGuard>
                }
              />
              <Route
                path="/questionbanck/edite-question-matching/:id/:subjectid"
                element={
                  <AuthGuard requiredRoles={["QuestionsBank"]}>
                    <EditeQuestionMatching />
                  </AuthGuard>
                }
              />
              <Route
                path="/exams/:page/:search"
                element={
                  <AuthGuard requiredRoles={["Tests"]}>
                    <Exams />
                  </AuthGuard>
                }
              />
              <Route
                path="/exams/add"
                element={
                  <AuthGuard requiredRoles={["AddTest"]}>
                    <ExamsAdd />
                  </AuthGuard>
                }
              />
              <Route
                path="/exams/edit/:id/:subjectid/:classroomid"
                element={
                  <AuthGuard requiredRoles={["EditTest"]}>
                    <ExamsEdit />
                  </AuthGuard>
                }
              />
              <Route
                path="/exams/repetiton/:id/:subjectid/:classroomid"
                element={
                  <AuthGuard>
                    <ExamsRepetiton />
                  </AuthGuard>
                }
              />
              <Route
                path="/exams/list-exams/:id/:subjectid/:classroomid"
                element={
                  <AuthGuard requiredRoles={["ViewAllTestStudents"]}>
                    <ListStudents />
                  </AuthGuard>
                }
              />
              <Route
                path="/students/:page/:search/:sortclasscode/:sortclassroomnamber"
                element={
                  <AuthGuard requiredRoles={["Students"]}>
                    <Students />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/survey"
                element={
                  <AuthGuard requiredRoles={["Survey"]}>
                    <Survey />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/survey/survey-view/:id/:page/:search/:sortclassroom"
                element={
                  <AuthGuard requiredRoles={["Survey"]}>
                    <SurveyView />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/survey/survey-view/list-survey/:page/:search/:sortclassroom/:sortclassroomnumberid/:subjectid/:surveytypeid"
                element={
                  <AuthGuard requiredRoles={["Survey"]}>
                    <ListSurveyStudents />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/survey/survey-view/show-survey/:page/:studentid/:subjectid/:surveytypeid"
                element={
                  <AuthGuard requiredRoles={["Survey"]}>
                    <ShowSurvey />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/survey/show-survey/show-staff-survey/:id"
                element={
                  <AuthGuard requiredRoles={["Survey"]}>
                    <ShowStaffSurvey />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/survey/viewquestion/:id"
                element={
                  <AuthGuard requiredRoles={["Survey"]}>
                    <SurveyQuestions />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/notifications"
                element={
                  <AuthGuard>
                    <Notifications />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/moderators/:page/:search/:sortrole"
                element={
                  <AuthGuard requiredRoles={["Users"]}>
                    <Moderators />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/signin"
                element={
                  <AuthGuard>
                    <SingIn />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/powers/:search"
                element={
                  <AuthGuard requiredRoles={["Roles"]}>
                    <Powers />
                  </AuthGuard>
                }
              />
              <Route
                path="/powers/add"
                element={
                  <AuthGuard requiredRoles={["AddRole"]}>
                    <AddPowers />
                  </AuthGuard>
                }
              />
              <Route
                path="/powers/edit/:id"
                element={
                  <AuthGuard requiredRoles={["EditRole"]}>
                    <EditPowers />
                  </AuthGuard>
                }
              />
              <Route
                path="/agreement"
                element={
                  <AuthGuard requiredRoles={["TestAgreement"]}>
                    <Agreement />
                  </AuthGuard>
                }
              />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </main>
  );
}

export default App;
