import React, { useRef } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

ChartJS.register(ArcElement, Tooltip, Legend);

export const NoDataPie = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        aspectRatio: "1/1",
        background: "#000",
        color: "white",
        borderRadius: "9999px",
        justifyContent: "center",
        margin: "0 24px",
        minWidth: "300px",
      }}
    >
      لا توجد بيانات
    </div>
  );
};

export const ChartSurveyQuestions = (props) => {
  const { Arraydata, surveyTypeName, setSurveyTypeName } = props;

  // 1) Create a ref to capture the rendered Pie
  const chartRef = useRef(null);

  const dataLoop = [...Arraydata.data];
  const dataLabels = [...Arraydata.label];
  const backgroundColor = [
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
    "#917373",
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
  ];

  const data = {
    labels: dataLabels,
    animations: {
      tension: {
        duration: 500,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 50,
      },
    },
    datasets: [
      {
        label: dataLabels,
        backgroundColor,
        borderColor: "none",
        borderWidth: 0,
        data: dataLoop.map((itm) => +itm),
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "right",
        rtl: true,
      },
      datalabels: {
        borderWidth: (a) => (a.dataset.data[a.dataIndex] === 0 ? 0 : 1),
        color: "white",
        font: { weight: "bold" },
        padding: 0,
        formatter: (value) => {
          if (+value > 0) {
            return Math.round(value) + "%";
          }
          return "";
        },
      },
    },
  };

  const handleDownloadPDF = async () => {
    if (!chartRef.current) return;

    try {
      const canvas = await html2canvas(chartRef.current);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        unit: "px",
        format: "a4",
      });

      pdf.addImage(imgData, "PNG", 20, 20, 300, 300);
      pdf.save("chart-survey-questions.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const isNoData = !dataLoop?.length || dataLoop.every((itm) => itm === "0");

  return (
    <div className="home-content__chart_row" style={{ width: "auto" }}>
      <h3>أسئلة الاستبيانات {surveyTypeName && <>({surveyTypeName})</>}</h3>
      <div className="home-content__chart-list">
        <div className="home-content__chart-list__item">
          <div className="cartitem" ref={chartRef}>
            <div className="list">
              {dataLabels.map((label, index) => (
                <div className="list-item" key={index}>
                  <span
                    className="scroil"
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                    }}
                  />
                  <span className="label">{label}</span>
                </div>
              ))}
            </div>
            {isNoData ? (
              <NoDataPie />
            ) : (
              // Attach the ref to Pie
              <Pie data={data} options={options} />
            )}
          </div>
        </div>
      </div>
      {!isNoData && (
        <button
          className="btn btn-primary"
          style={{ width: "100%", display: "none" }}
          onClick={handleDownloadPDF}
        >
          تحميل كــــ PDF
        </button>
      )}
    </div>
  );
};
export const ChartQuestionnaireStatistics = (props) => {
  const { Arraydata } = props;

  // 1) Create a ref
  const chartRef = useRef(null);

  const dataLoop = [...Arraydata.data];
  const dataLabels = [...Arraydata.label];
  const backgroundColor = [
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
    "#917373",
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
  ];

  const data = {
    labels: dataLabels,
    animations: {
      tension: {
        duration: 500,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 50,
      },
    },
    datasets: [
      {
        label: dataLabels,
        backgroundColor,
        borderColor: "none",
        borderWidth: 0,
        data: dataLoop,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "right",
        rtl: true,
      },
      datalabels: {
        borderWidth: (a) => (a.dataset.data[a.dataIndex] === 0 ? 0 : 1),
        color: "white",
        font: { weight: "bold" },
        padding: 0,
        formatter: (value) => {
          if (+value > 0) {
            return value + "%";
          }
          return "";
        },
      },
    },
  };

  const handleDownloadPDF = async () => {
    if (!chartRef.current) return;

    try {
      const canvas = await html2canvas(chartRef.current);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        unit: "px",
        format: "a4",
      });

      pdf.addImage(imgData, "PNG", 20, 20, 300, 300);
      pdf.save("chart-survey-questions.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const isNoData = !dataLoop?.length || dataLoop.every((itm) => itm === "0");

  return (
    <div className="home-content__chart_row" style={{ width: "auto" }}>
      <h3 style={{ height: "22px" }}></h3>
      <div className="home-content__chart-list">
        <div className="home-content__chart-list__item">
          <div className="cartitem" ref={chartRef}>
            <div className="list">
              {dataLabels.map((label, index) => (
                <div className="list-item" key={index}>
                  <span
                    className="scroil"
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                    }}
                  />
                  <span className="label">{label}</span>
                </div>
              ))}
            </div>
            {isNoData ? (
              <NoDataPie />
            ) : (
              // 3) Attach the ref
              <Pie data={data} options={options} />
            )}
          </div>
        </div>
      </div>
      {!isNoData && (
        <button
          className="btn btn-primary"
          style={{ width: "100%", display: "none" }}
          onClick={handleDownloadPDF}
        >
          تحميل كــــ PDF
        </button>
      )}
    </div>
  );
};
