import React, { useEffect, useMemo, useState } from "react";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import { useParams } from "react-router";
import SurveyViewContent from "../component/survey-view";
import axiosInstance from "../../../api/axios_instance.jsx";
import {
  downloadBase64File,
  downloadBase64FileWithoutAtob,
} from "../../../components/shared/utils.js";
import { useSearchParams } from "react-router-dom";
import { useArrayQueryParam } from "../component/survey-view/array-search-param-hook.jsx";

const SurveyView = () => {
  const { id, page, search, sortclassroom } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDate = searchParams.get("fromDate") || "";
  const toDate = searchParams.get("toDate") || "";
  const [subjects] = useArrayQueryParam("subjects");

  const pageCount = page.slice(page.indexOf(":") + 1, page.length);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);

  const [classRoom, _] = useArrayQueryParam("classRooms");
  // sortclassroom
  //   .slice(sortclassroom.indexOf(":") + 1, sortclassroom.length)
  //   .split(",") || [];
  const [classRoomNumbers] = useArrayQueryParam("classRoomNumbers");
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perpage, setPerpage] = useState(0);
  const [PageSize, setPageSize] = useState(10);

  useEffect(() => {
    const from = startDay || "";
    const to = endDay || "";
    if (from === fromDate && to === toDate) return;
    searchParams.set("fromDate", startDay?.toLocaleDateString("en-CA") || "");
    searchParams.set("toDate", endDay?.toLocaleDateString("en-CA") || "");
    setSearchParams(searchParams);
  }, [startDay, endDay]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/survey`,
      title: "الاستبيانات",
    },
  ];

  const downloadFile = useMemo(
    () =>
      GetDataFile.bind(
        null,
        id,
        pageCount,
        searchKeyword,
        classRoom,
        classRoomNumbers,
        subjects,
        fromDate,
        toDate,
        PageSize
      ),
    [
      id,
      pageCount,
      searchKeyword,
      classRoom,
      classRoomNumbers,
      subjects,
      fromDate,
      toDate,
      PageSize,
    ]
  );
  useEffect(() => {
    setLoading(false);
    GetData(
      id,
      pageCount,
      searchKeyword,
      classRoom,
      classRoomNumbers,
      subjects,
      fromDate,
      toDate,
      PageSize,
      setPerpage,
      setData,
      setLoading
    );
  }, [
    pageCount,
    PageSize,
    searchKeyword,
    JSON.stringify(classRoom),
    JSON.stringify(classRoomNumbers),
    JSON.stringify(subjects),
    fromDate,
    toDate,
    id,
  ]);

  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <SurveyViewContent
            downloadFile={downloadFile}
            ArrayData={data}
            setStartDay={setStartDay}
            setEndDay={setEndDay}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={() => {}}
            PageSize={PageSize}
            setPageSize={setPageSize}
            classRoomID={classRoom}
            loading={loading}
          />
        </div>
      </div>
    </section>
  );
};

export default SurveyView;

const GetData = async (
  id,
  pageCount,
  searchKeyword,
  classRoom,
  classRoomNumbers,
  subjects,
  startDay,
  endDay,
  PageSize,
  setPerpage,
  setData,
  setLoading
) => {
  const options = {
    method: "post",
    url:
      id === "4" ? `${Api}Survey/GetStaffList` : `${Api}Survey/SearchForSurvey`,
    headers: Headers,
    data: {
      pageNumber: pageCount,
      surveyTypeId: id,
      searchKeyword: searchKeyword,
      classRoomNumbers: classRoomNumbers,
      classRooms: classRoom,
      subjects: id !== "3" ? [] : subjects || [],
      fromDate: startDay === "" ? null : startDay,
      toDate: endDay === "" ? null : endDay,
      pageSize: PageSize,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataFile = async (
  id,
  pageCount,
  searchKeyword,
  classRoom,
  classRoomNumbers,
  subjects,
  startDay,
  endDay,
  PageSize,
  fileType // 1 excel - 2 pdf
) => {
  const options = {
    method: "post",
    url:
      id === "4" ? `${Api}Survey/GetStaffList` : `${Api}Survey/SearchForSurvey`,
    headers: Headers,
    data: {
      pageNumber: pageCount,
      surveyTypeId: id,
      searchKeyword: searchKeyword,
      classRooms: classRoom,
      classRoomNumbers: classRoomNumbers,
      subjects: subjects,
      fromDate: startDay === "" ? null : startDay,
      toDate: endDay === "" ? null : endDay,
      pageSize: PageSize,
      IsExport: true,
      ExportTypeId: fileType,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      const file = response.data;
      downloadBase64FileWithoutAtob(
        file.fileContents,
        file.fileDownloadName,
        file.contentType
      );
    })
    .catch(function (error) {});
};
