/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { Api } from "../../../api";
import { Headers } from "../../../api/actions";
import axiosInstance from "../../../api/axios_instance";
import { FilterReset } from "../../shared/reset-filter";

const ChartTop = (props) => {
  const {
    surveyTypeIds,
    setSurveyTypeIds,
    classRoomCodes,
    setClassRoomCodes,
    classRoomNumbers,
    setClassRoomNumbers,
    subjectCodes,
    setSubjectCodes,
    surveyTypeName,
    setSurveyTypeName,
    classRoomIds,
    setClassRoomIds,
    subjectIds,
    setSubjectIds,
  } = props;

  return (
    <div className="home-content__top">
      <QuestionnaireType
        surveyTypeIds={surveyTypeIds}
        setSurveyTypeIds={setSurveyTypeIds}
        surveyTypeName={surveyTypeName}
        setSurveyTypeName={setSurveyTypeName}
      />
      {!!surveyTypeIds?.length && surveyTypeIds[0] != "4" && (
        <>
          {
            // surveyTypeIds[0] == "3" && (
            <QuestionSubjects
              classRooms={classRoomIds}
              subjectIds={subjectIds}
              setSubjectIds={setSubjectIds}
              subjectCodes={subjectCodes}
              setSubjectCodes={setSubjectCodes}
            />
          }

          <CourseCode
            subjectIds={subjectIds}
            classRoomIds={classRoomIds}
            setClassRoomIds={setClassRoomIds}
            classRoomCodes={classRoomCodes}
            setClassRoomCodes={setClassRoomCodes}
          />

          {/* <Course
        classRoomName={classRoomName}
        setClassRoomName={setClassRoomName}
      /> */}
          {!!classRoomIds?.length && (
            <CourseNumber
              classRoomIds={classRoomIds}
              classRoomNumbers={classRoomNumbers}
              setClassRoomNumbers={setClassRoomNumbers}
            />
          )}
        </>
      )}
      {!!(
        surveyTypeIds?.length ||
        subjectIds?.length ||
        classRoomCodes?.length ||
        classRoomNumbers?.length
      ) && (
        <FilterReset
          resetFilters={() => {
            setSurveyTypeIds([]);
            setSubjectIds([]);
            setSubjectCodes([]);
            setClassRoomCodes([]);
            setClassRoomNumbers([]);
            setClassRoomIds([]);
          }}
        />
      )}
    </div>
  );
};
export default ChartTop;

const QuestionnaireType = (props) => {
  const { surveyTypeIds, setSurveyTypeIds, surveyTypeName, setSurveyTypeName } =
    props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSurveyTypes(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">نوع الاستبيان</span>
        </button>
      </div>
    );
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">نوع الاستبيان</span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                checked={surveyTypeIds?.includes(item.id)}
                id="flexCheckDefault"
                onChange={(e) => {
                  e.preventDefault();
                  setSurveyTypeIds((prv) =>
                    prv.includes(item.id)
                      ? prv.filter((id) => id != item.id)
                      : [item.id]
                  );
                  setSurveyTypeName(
                    surveyTypeName === item.name ? "" : item.name
                  );
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.name}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const QuestionSubjects = (props) => {
  const {
    subjectIds,
    subjectCodes,
    setSubjectCodes,
    setSubjectIds,
    classRooms,
  } = props;

  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSubjectClassRooms(setData, setLoading, classRooms);
  }, [JSON.stringify(classRooms)]);

  useEffect(() => {
    setSubjectCodes(
      subjectCodes.filter((code) => data?.some((item) => item.code === code))
    );
    setSubjectIds(
      subjectIds.filter((value) => data?.some((item) => item.value === value))
    );
  }, [JSON.stringify(data)]);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">المواد</span>
        </button>
      </div>
    );
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">المواد</span>
      </button>
      <ul className="dropdown-menu">
        <input
          style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
          type="text"
          placeholder="بحث"
          onChange={(e) => {
            if (e.target.value)
              setSearchedData(
                data.filter((item) => item.label.includes(e.target.value))
              );
            else setSearchedData(undefined);
          }}
        />
        {(searchedData || data).map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.code}
                checked={subjectCodes?.includes(item.code)}
                id="flexCheckDefault"
                onChange={(e) => {
                  e.preventDefault();
                  setSubjectCodes((prv) =>
                    prv.includes(item.code)
                      ? prv.filter((code) => code != item.code)
                      : [...prv, item.code]
                  );
                  setSubjectIds((prv) =>
                    prv.includes(item.value)
                      ? prv.filter((value) => value != item.value)
                      : [...prv, item.value]
                  );
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.label}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const CourseCode = (props) => {
  const {
    subjectIds,
    classRoomCodes,
    classRoomIds,
    setClassRoomCodes,
    setClassRoomIds,
  } = props;
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const handleData = (data) => {
    if (data) setData(removeDuplicates(data, "code"));
  };
  // useEffect(() => {
  //   GetDataClassRooms(handleData, setLoading);
  // }, [subjectIds]);

  useEffect(() => {
    GetDataClassRoomsNumbersBySubjectIds(subjectIds, handleData, setLoading);
  }, [JSON.stringify(subjectIds)]);

  useEffect(() => {
    setClassRoomCodes(
      classRoomCodes.filter((code) => data.some((itm) => itm.code === code))
    );
    setClassRoomIds(
      classRoomIds.filter((id) => data.some((itm) => itm.id === id))
    );
  }, [JSON.stringify(data)]);
  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">اسم الدورة</span>
        </button>
      </div>
    );
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">اسم ورمز الدورة</span>
      </button>
      <ul
        className="dropdown-menu"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <input
          style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
          type="text"
          placeholder="بحث"
          onChange={(e) => {
            if (e.target.value)
              setSearchedData(
                data.filter(
                  (item) =>
                    item.name.includes(e.target.value) ||
                    item.code.includes(e.target.value)
                )
              );
            else setSearchedData(undefined);
          }}
        />
        {(searchedData || data).map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="CourseCode"
                checked={classRoomCodes?.includes(item.code)}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomCodes((prv) =>
                    prv.includes(item.code)
                      ? prv.filter((id) => id != item.code)
                      : [...prv, item.code]
                  );
                  setClassRoomIds((prv) =>
                    prv.includes(item.id)
                      ? prv.filter((id) => id != item.id)
                      : [...prv, item.id]
                  );
                }}
              />
              <label className="form-check-label" htmlFor="CourseCode">
                <span>
                  {item.name} - {item.code}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const Course = (props) => {
  const { classRoomName, setClassRoomName } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleData = (data) => {
    if (data) setData(removeDuplicates(data, "nameAr"));
  };
  useEffect(() => {
    GetDataClassRooms(handleData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">الدورة</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">الدورة</span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={classRoomName == item.nameAr}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomName(item.nameAr);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>
                  {item.nameAr} - {item.code}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const CourseNumber = (props) => {
  const { classRoomNumbers, setClassRoomNumbers, classRoomIds } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleData = (data) => {
    if (data) setData(data);
  };
  useEffect(() => {
    if (classRoomIds)
      GetDataNumberClassRooms(classRoomIds, null, handleData, setLoading);
    else setData([]);
    setClassRoomNumbers([]);
  }, [classRoomIds]);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">رقم الدورة</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text"> رقم الدورة </span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={classRoomNumbers?.includes(item.id)}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomNumbers((prv) =>
                    prv.includes(item.id)
                      ? prv.filter((id) => id != item.id)
                      : [...prv, item.id]
                  );
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.name}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const MaterialSymbol = (props) => {
  const { subjectCode, setSubjectCode } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSubjects(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">رمز الماده</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">رمز الماده</span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.code}
                id="flexCheckDefault"
                checked={subjectCode == item.code}
                onChange={(e) => {
                  e.preventDefault();
                  setSubjectCode(item.code);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.code}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const Material = (props) => {
  const { subjectName, setSubjectName } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSubjects(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text"> الماده </span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text"> الماده </span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={subjectName == item.nameAr}
                onChange={(e) => {
                  e.preventDefault();
                  setSubjectName(item.nameAr);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.nameAr}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
// Get Data To Api
// get Survey Types
const GetDataSurveyTypes = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}Survey/GetSurveyTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetDataSubjectClassRooms = async (setData, setLoading, classRooms) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetSubjectsSelectListByClassroomIds`,
    headers: Headers,
    data: classRooms,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(
        response.data.responseObject.map((itm) => ({
          value: itm.id,
          label: itm.name + " - " + itm.code,
          code: itm.code,
        }))
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
// get Class Rooms
const GetDataClassRooms = async (setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("pageSize", 10000000);
  const options = {
    method: "post",
    url: `${Api}ClassRoom/SearchForClassRooms`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetDataClassRoomsNumbersBySubjectIds = async (
  subjectIds,
  setData,
  setLoading
) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersSelectListBySubjectIds`,
    headers: Headers,
    data: subjectIds,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataNumberClassRooms = async (
  classRoomIds,
  subjectId,
  setData,
  setLoading
) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersSelectListByCourseIds`,
    headers: Headers,
    data: classRoomIds,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const Icon = () => {
  return (
    <span className="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M6.5 9L12.5 15L18.5 9"
          stroke="#5E6366"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

// get Subjects
const GetDataSubjects = async (setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("classRoomId", 0);
  data.append("pageSize", 10);

  const options = {
    method: "post",
    url: `${Api}Subject/SearchForSubjects`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

function removeDuplicates(arr, key) {
  // Create an empty object to track seen values
  const seen = {};

  // Use the filter method to return only unique objects
  return arr.filter((item) => {
    // Check if the key's value has been seen before
    if (seen[item[key]]) {
      return false;
    } else {
      // If not seen, mark it as seen and include it in the result
      seen[item[key]] = true;
      return true;
    }
  });
}
