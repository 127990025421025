import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import { Headers } from "../../../api/actions";
import axios from "axios";
import Loading from "../../../components/loading/loading";
import ChartsContent from "../../../components/home-sections/survey-chart-sections/charts-content";
import axiosInstance from "../../../api/axios_instance";

const SurveysChart = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [surveyTypeIds, setSurveyTypeIds] = useState([]);
  const [classRoomCodes, setClassRoomCodes] = useState([]);
  const [classRoomNumbers, setClassRoomNumbers] = useState([]);
  const [classRoomNames, setClassRoomNames] = useState([]);
  const [subjectCodes, setSubjectCodes] = useState([]);
  const [surveyTypeName, setSurveyTypeName] = useState("");

  useEffect(() => {
    GetData(
      setData,
      setLoading,
      surveyTypeIds,
      classRoomNumbers,
      classRoomCodes,
      classRoomNames,
      subjectCodes
    );
  }, [
    surveyTypeIds,
    classRoomNumbers,
    classRoomCodes,
    classRoomNames,
    subjectCodes,
  ]);

  if (!loading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <ChartsContent
        Arraydata={data}
        surveyTypeIds={surveyTypeIds}
        setSurveyTypeIds={setSurveyTypeIds}
        classRoomCodes={classRoomCodes}
        setClassRoomCodes={setClassRoomCodes}
        classRoomNumbers={classRoomNumbers}
        setClassRoomNumbers={setClassRoomNumbers}
        classRoomNames={classRoomNames}
        setClassRoomNames={setClassRoomNames}
        subjectCodes={subjectCodes}
        setSubjectCodes={setSubjectCodes}
        surveyTypeName={surveyTypeName}
        setSurveyTypeName={setSurveyTypeName}
      />
    </>
  );
};

export default SurveysChart;

const GetData = async (
  setData,
  setLoading,
  surveyTypeIds,
  classRoomNumbers,
  classRoomCodes,
  classRoomNames,
  subjectCodes
) => {
  const options = {
    method: "post",
    url: `${Api}Charts/SurveyChartMultiple`,
    headers: Headers,
    data: {
      surveyTypeIds: surveyTypeIds || [],
      classRoomNumbers: classRoomNumbers || [],
      classRoomCodes: classRoomCodes || [],
      classRoomNames: [],
      subjectCodes: surveyTypeIds?.[0] !== 3 ? [] : subjectCodes || [],
      subjectNames: [],
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
// const GetData = async (
//   setData,
//   setLoading,
//   surveyTypeId,
//   classRoomNumber,
//   classRoomCode,
//   classRoomName
// ) => {
//   const options = {
//     method: "post",
//     url: `${Api}Charts/SurveyChart`,
//     headers: Headers,
//     data: {
//       surveyTypeId: surveyTypeId === null ? 0 : surveyTypeId,
//       classRoomNumber: classRoomNumber === null ? "" : classRoomNumber,
//       classRoomCode: classRoomCode === null ? "" : classRoomCode,
//       classRoomName: classRoomName === null ? "" : classRoomCode,
//       subjectCode: "",
//       subjectName: "",
//     },
//   };
//   await axiosInstance(options)
//     .then(function (response) {
//       setData(response.data.responseObject);
//       setLoading(true);
//     })
//     .catch(function (error) {
//       setLoading(true);
//     });
// };
/*
survey-chart-sections
ClassRoom
TestChart
StudentChart
student-chart-sections
test-chart-sections
classRoom-chart-sections

*/
